<section class="px-md-5 px-2 pt-5">
    <div class="row">
        <div class="col-12 col-md-6 d-flex flex-column align-items-center align-items-md-start px-1 p-0">
            <img [src]="data.start.logo" [alt]="data.start.logoAlt" loading="lazy" class="w-80">
            <p class="fs-6 mt-5 mx-3 mx-md-0">{{data.start.desc}}</p>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <img [src]="data.start.image" [alt]="data.start.imageAlt" loading="lazy" class="w-80">
        </div>
    </div>
    <p class="fw-semibold text-center my-5 h4 mx-2 mx-md-0">{{data.start.features}}</p>
    <div class="w-100 px-md-5 d-flex justify-content-around flex-column flex-md-row align-items-center ">
        <a class="btn btn-primary anchor-button rounded-pill fs-5 fw-semibold" *ngFor="let item of data.start.buttons"
            [href]="item.href" [target]="item.target">{{item.text}}</a>
    </div>
</section>

<!-- overview -->
<section class="px-md-5 my-5 overview px-2">
    <div class="row px-md-5 py-4">
        <div class="col-12 col-md-6">
            <p class="h2 fw-bold clr-white mb-4">Overview</p>
            <p [innerHTML]="data.overview.desc" class="clr-white fs-6 lh-base"></p>
            <a class="btn btn-primary hover-popup fs-6 rounded-pill fw-bold"
                href="https://cdn.cosgrid.com/website/strapi/MicroZAccess%20ZTNA%202.0%20Whitepaper_9b128991-fecb-463c-84d3-53d8ac81fbb2.pdf"
                target="_blank" style="width: 50%">Download Whitepaper</a>
        </div>
        <div class="col-12 col-md-6">
            <carousel-list [data]="data.overview.carousel" [baseUrl]="data.overview.carouselUrl"
                [descOnly]="true"></carousel-list>
        </div>
    </div>
</section>

<!-- key differentiator -->
<section class="px-md-5 my-5">
    <div class="key-differentiators-container py-lg-5 py-1 row">
        <div class="col-12 col-md-3" *ngFor="let item of data.keyDifferentiators">
            <div class="d-flex align-items-center p-3 shadow my-1 hover-popup rounded-5 my-2 w-100 h-100">
                <img [src]="item.imgURL" alt="image" height="80px" width="80px" class="rounded-circle"
                    [alt]="item?.title" loading="lazy" />
                <div class="d-flex ml-2 flex-column justify-content-center align-items-center">
                    <p class="fs-5 m-0 fw-bold">{{ item.title }}</p>
                    <p class="fs-4 m-0 fw-semibold">
                        {{ item.percent }}<i class="fa ms-1" [ngClass]="item.arrowStyle" style="color: #0078be"></i>
                    </p>
                    <p class="fs-6 m-0">{{item.subtitle}}</p>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- description -->
<section class="px-md-5 my-5 px-2">
    <div class="d-flex justify-content-center align-items-center positon-relative flex-column flex-md-row">
        <p class="clr-white bg-clr-blue-dark fs-3 fw-bold p-1 rounded m-0">Secure VPN + Zero Trust Access + Web
            Filtering
            =</p>
        <img src="assets/images/home/MicroZAccess_1.png" alt="MicroZAccess" class="position-absolute"
            class="description-img">
    </div>
    <ul class="ms-md-5 mt-5">
        <li *ngFor="let item of ztna.description" [innerHTML]="item" class="fs-6"></li>
    </ul>
</section>

<!-- features -->
<section class="px-md-5 my-5 features px-2">
    <div class="row py-4 flex-column-reverse flex-md-row">
        <div class="col-12 col-md-6">
            <div class="row">
                <div class=" col-12 col-md-6" *ngFor="let item of data.features.points">
                    <div class="feature-box d-flex align-items-center hover-popup m-2 p-2">
                        <img [src]="item.imgUrl" [alt]="item.title" loading="lazy" height="70rem" width="70rem"
                            class="mb-4" />
                        <p class="lh-1 clr-white ms-3 fs-6">{{ item.title }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <p class="h2 fw-bold clr-white mb-3 mb-md-5">Features</p>
            <p class="fs-6 clr-white">{{data.features.desc}}</p>
        </div>
    </div>
</section>

<!-- how does it works -->
<section class="px-md-5 my-5 px-2">
    <p class="h2 fw-bold clr-blue-dark mb-4 text-center">{{ztna.howDoesItWorks.title}}</p>
    <div class="row flex-column-reverse flex-md-row">
        <div class="d-flex justify-content-center flex-column col-md-4 col-12">
            <p class="fs-6 mt-5 mt-md-0">{{ztna.howDoesItWorks.desc}}</p>
            <ul class="">
                <li *ngFor="let item of ztna.howDoesItWorks.points" [innerHTML]="item" class="fs-6"></li>
            </ul>
            <a [href]="ztna.howDoesItWorks.btnLink" target="_blank"
                class="btn btn-primary fs-6 fw-semibold w-70 rounded-pill hover-popup">{{ztna.howDoesItWorks.btnText}}</a>
        </div>
        <div class="d-flex justify-content-center align-items-center col-md-8 col-12">
            <img [src]="ztna.howDoesItWorks.img" [alt]="ztna.howDoesItWorks.title" class="w-80" loading="lazy">
        </div>

    </div>
</section>

<!-- web Filtering -->
<section class="px-md-5 my-5 px-2">
    <p class="h2 fw-bold clr-blue-dark mb-4 text-center">{{ztna.webFiltering.title}}</p>
    <div class="row">
        <div class="d-flex justify-content-center align-items-center col-md-6 col-12">
            <img [src]="ztna.webFiltering.img" [alt]="ztna.webFiltering.title" class="w-80" loading="lazy">
        </div>
        <div class="d-flex justify-content-center flex-column col-md-6 col-12">
            <p class="fs-6 mt-4 mt-md-0" [innerHTML]="ztna.webFiltering.desc"></p>
        </div>
    </div>
</section>

<!-- highlights -->
<section class="px-md-5 my-5">
    <div class="highlights p-3">
        <p class="fw-bold clr-white h2">Highlights</p>
    </div>
    <div class="row d-flex justify-content-center align-items-center h-100 py-lg-5 py-1 px-90 integration-row">
        <div class="col-md-3 col-12 image-box d-flex justify-content-center align-items-center hover-popup"
            style="height: 140px !important;" *ngFor="let item of data.highlights">
            <div
                class="d-flex flex-column justify-content-center align-items-center w-90 h-90 p-3 image-anchor bg-clr-white">
                <img [src]="item.icon" alt="" loading="lazy" class="w-20 mb-1">
                <p class="h-20 text-center" style="max-height: 80px; object-fit: contain;">{{item.desc}}</p>
            </div>
        </div>
    </div>
</section>

<!-- usecases - ztna -->
<section class="px-md-5 my-5 px-2">
    <p class="fw-bold clr-blue-dark h2 text-center">Use Cases</p>
    <slider-list class="d-flex flex-column justify-content-center align-items-center" [data]="{
        data: data.usecases.data,
        imgBaseUrl: data.usecases.imgUrl
    }"></slider-list>
</section>

<!-- benifits -->
<section class="px-md-5 my-5 px-2">
    <!-- <image-points [data]="data.benefits.desc" title="Benefits" [image]="data.benefits.image" leftContentXLCol="7"
        imagePosition="2"></image-points> -->
    <p class="fw-bold clr-blue-dark h2 text-center mb-5">Benefits</p>
    <div class="row flex-column flex-md-row">
        <div class="col-md-4 col-12 d-flex flex-column justify-content-between order-2 order-md-1">
            <div *ngFor="let item of ztna.benefits.left" class="">
                <p class="fs-3 fw-semibold clr-blue-dark mb-0">{{item.title}}</p>
                <ul>
                    <li *ngFor="let point of item.points" [innerHTML]="point"></li>
                </ul>
            </div>
        </div>
        <div class="col-md-4 col-12 d-flex justify-content-center align-items-center order-1 order-md-2">
            <img [src]="ztna.benefits.image" alt="ZTNA Benefits" class="w-90">
        </div>
        <div class="col-md-4 col-12 d-flex flex-column justify-content-between order-3 order-md-3">
            <div *ngFor="let item of ztna.benefits.right">
                <p class="fs-3 fw-semibold clr-blue-dark mb-0">{{item.title}}</p>
                <ul>
                    <li *ngFor="let point of item.points" [innerHTML]="point"></li>
                </ul>
            </div>
        </div>
    </div>

</section>



<!-- Integration support - ztna -->
<section class="px-md-5 my-5">
    <div class="p-3"
        style="background: linear-gradient(90deg, #6801AF 0%, #00B3F0 100%); border-top-right-radius: 20px">
        <p class="fw-bold clr-white h2">Integration Support</p>
        <p class="clr-white fs-5">Cloud Integration Support & SSO for the following platforms</p>
    </div>
    <div class="row d-flex justify-content-center align-items-center h-100 py-lg-5 py-1 integration-row">
        <div class="col-md-3 col-12 image-box d-flex justify-content-center align-items-center hover-popup"
            *ngFor="let item of data.integration">
            <a [href]="item.link" target="_blank"
                class="d-flex justify-content-center w-90 h-90 p-3 image-anchor bg-clr-white"><img [src]="item?.imgURL"
                    [alt]="item?.name" loading="lazy" class="img-fluid5" style="cursor: pointer"
                    style="max-height: 80px; object-fit: contain" [attr.width]="item.width" />
            </a>
            <!-- </div> -->
        </div>
    </div>
</section>



<!-- how to start - ztna -->
<section class="px-md-5 my-5 px-2">
    <p class="fw-bold text-center clr-blue-dark h2">How to get started</p>
    <img src="assets/images/mza/how-to-start.png" alt="how-to-start" width="100%" class="mb-lg-5" loading="lazy">
    <p class="mt-lg-5 mt-3 fw-bold h3">Zero Trust Access to Cloud Application and Multi-cloud Infra using MicroZAccess
        Flow of events</p>
    <img src="assets/images/mza/flow-of-events.png" alt="flow-of-events" width="100%" loading="lazy">
</section>

<!-- video reference - ztna -->
<section id="video-reference" class="px-md-5 my-5 px-2">
    <div class="row">
        <div class="col-12 col-md-6">
            <iframe class="video rounded-5" width="100%" height="290"
                src="https://www.youtube-nocookie.com/embed/PUu4_-0eXSc" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen loading="lazy"></iframe>
        </div>
        <div class="col-12 col-md-6 d-flex align-content-center flex-column justify-content-center">
            <p class="text-center fw-bold h2">You Know it all now!</p>
            <p class="text-center fs-6">Learn Further from our <a routerLink="/resources/faqs">FAQ
                    page</a></p>
            <a class="btn align-self-center hover-popup" href="http://cosgrid.net/auth/social-signup"
                style="background-color: #0078d7; color: white">Click for a Free Trail</a>
        </div>
    </div>
</section>

<!-- contact us -->
<section class="px-md-5 my-5">
    <div style="background-color: #ededed !important;">
        <div class="container-fluid">
            <div class="d-flex flex-column justify-content-center align-items-center py-4">
                <p class="text-center text-md-left fw-bold h2">Interested in this product?</p>
                <p class="fs-6">Click the button below & submit your details - we will call you back!</p>
                <a class="btn hover-popup" routerLink="/company/contacts"
                    style="background-color: #0078be; color: white">Contact Us</a>
            </div>
        </div>
    </div>
</section>